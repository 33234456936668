import React, { FormEventHandler, useRef } from 'react';
import { Form, Input } from 'antd';

interface IInput {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  onInput?: FormEventHandler<HTMLInputElement> | undefined;
  textArea?: boolean;
  search?: boolean;
  onSearch?: (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  type?: string;
  textEditor?: boolean;
  labelCol?: { span: number };
  maxLength?: number;
  readonly?: boolean;
  disabled?: boolean;
}

const FormInput: React.FC<IInput> = ({
  name,
  label,
  required,
  placeholder,
  onInput,
  textArea,
  search,
  onSearch,
  type,
  labelCol,
  maxLength,
  readonly,
  disabled
}) => {
  const ref = useRef(null);

  const settings = {
    name,
    label,
    valuePropName: 'value',
    hasFeedback: required ? true : undefined,
    rules: required
      ? [
          {
            required: true,
            message: 'Заполните поле',
          },
          {
            validator: (_: any, e: any) => {
              if (!ref.current) return Promise.reject('');

              let value = '';

              if (!textArea) {
                const inputValue: string | number = (ref.current as any).input.value;

                if (typeof inputValue === 'string') {
                  value = inputValue.trim();
                } else if (type === 'number') {
                  const str = String(value).replace(/\D/g, '');
                  if (str.length > 0) return Promise.reject('');
                }
              } else if (textArea) {
                value = (ref.current as any).resizableTextArea?.textArea?.value.trim();
              }

              if (!value) return Promise.reject('');

              return Promise.resolve();
            },
            message: `Поле должно содержать ${type === 'number' ? 'цифры' : 'значение'}`,
          },
        ]
      : undefined,
    labelCol,
  };

  return (
    <Form.Item {...settings}>
      {textArea ? (
        <Input.TextArea
          ref={ref}
          autoSize={true}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
        />
      ) : search ? (
        <Input.Search
          ref={ref}
          type={type}
          placeholder={placeholder}
          allowClear
          onSearch={onSearch}
          maxLength={maxLength}
        />
      ) : (
        <Input
          ref={ref}
          type={type}
          placeholder={placeholder}
          onInput={onInput}
          maxLength={maxLength}
          readOnly={readonly}
          disabled={disabled}
        />
      )}
    </Form.Item>
  );
};

export default FormInput;
